body{
    
    background: linear-gradient(blue,yellow,pink,green);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-clip: border-box;
    
}

.form{
    align-items: center;
    align-content: center;
    margin-left: 40%;
    
}
.form-group{
    width: 40%;
    

}
.form-control{
    text-align: center;
}
.btn{
    margin-top: 2rem;
    
}
.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}
.set{
    margin-left: 20px;
    margin-top: 30px;
}
.btn{
    margin-left: 20px;
}